.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #21d7f9;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(6, 190, 225, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #5a5a5a;
  font-family: sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.4375rem + 0.75 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.4375rem;
  color: #06BEE1;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.4375rem + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 2.1875rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.1875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.1875rem;
  color: #5a5a5a;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.1875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.4375rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1rem;
  color: #5a5a5a;
  font-family: sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.1875rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  color: #5a5a5a;
  font-family: sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  color: #5a5a5a;
  font-family: sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.75rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.75rem;
  color: #5a5a5a;
  font-family: sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 0.875rem;
  }
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #06BEE1;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #5a5a5a;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(6, 190, 225, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"] {
  display: block;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #5a5a5a;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #06BEE1;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #5a5a5a;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(6, 190, 225, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #dadada;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #dadada;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f3f3f3;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #06BEE1;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #dadada;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #dadada;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid #dadada;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #5a5a5a;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #06BEE1 inset, 0 0 0 0.1875rem rgba(6, 190, 225, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #9a9a9a;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #9a9a9a;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #9a9a9a;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #9a9a9a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #06BEE1;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #06BEE1;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.1875rem;
  color: #5a5a5a;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #dadada;
  border-radius: 0.125rem;
  box-shadow: 0 0 0 #dadada inset;
  color: #5a5a5a;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #06BEE1;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #06BEE1;
  box-shadow: 0 0 0.1875rem #06BEE1 inset, 0 0 0 0.1875rem rgba(6, 190, 225, 0.2);
  color: #5a5a5a;
}
.input:focus,
.textarea:focus {
  border-color: #06BEE1;
  box-shadow: 0 0 0.1875rem #06BEE1 inset, 0 0 0 0.1875rem rgba(6, 190, 225, 0.2);
  color: #5a5a5a;
}
.input {
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 3.375rem;
  }
}
.textarea {
  line-height: 1.5;
  overflow-x: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #06BEE1;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #06BEE1;
  box-shadow: 0 0 0.1875rem #06BEE1 inset, 0 0 0 0.1875rem rgba(6, 190, 225, 0.2);
  color: #5a5a5a;
}
.control input:focus ~ .indicator {
  border-color: #06BEE1;
  box-shadow: 0 0 0.1875rem #06BEE1 inset, 0 0 0 0.1875rem rgba(6, 190, 225, 0.2);
  color: #5a5a5a;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #dadada;
  border-radius: 0.125rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #5a5a5a;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.0625rem solid #dadada;
  border-radius: 0.125rem;
  box-shadow: 0 0 0.1875rem #dadada inset;
  color: #5a5a5a;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #5a5a5a;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #06BEE1;
}
.select.error.focus {
  border-color: #06BEE1;
  box-shadow: 0 0 0.1875rem #06BEE1 inset, 0 0 0 0.1875rem rgba(6, 190, 225, 0.2);
  color: #5a5a5a;
}
.select.focus {
  border-color: #06BEE1;
  box-shadow: 0 0 0.1875rem #06BEE1 inset, 0 0 0 0.1875rem rgba(6, 190, 225, 0.2);
  color: #5a5a5a;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #5a5a5a;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 3.25rem;
  }
}
select option {
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 3.25rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #5a5a5a;
}
select:focus::-ms-value {
  background: none;
  color: #5a5a5a;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(6, 190, 225, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(6, 190, 225, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(6, 190, 225, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(6, 190, 225, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #dadada;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #06BEE1;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #06BEE1;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #06BEE1;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #06BEE1;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  .button {
    border-radius: 0.125rem;
    padding: 0 1.5em;
    background: #06BEE1;
    border: 0.0625rem solid #06BEE1;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(6, 190, 225, 0.2);
    outline: 0;
    background: #21d7f9;
    border-color: #21d7f9;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #21d7f9;
    border-color: #21d7f9;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #06BEE1 inset;
    background: #06BEE1;
    border: 0.0625rem solid #06BEE1;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .button.bottom_spacing {
    margin-bottom: 0.75em;
  }
  .button_overview {
    display: -webkit-box;
    display: flex;
  }
  .button_overview .button {
    margin-right: 0.75em;
  }
  .dialog_wrapper {
    display: none;
    margin: 1.5em 0;
    position: relative;
  }
  .dialog_wrapper.show {
    display: block;
  }
  [role="dialog"] {
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.2);
    outline: 0;
    padding: 1.5em;
    width: 100%;
    z-index: 10;
  }
  [role="dialog"] .title {
    padding: 0 1.875em 0 0;
  }
  [role="dialog"] .description {
    padding: 0 0 0.75em 0;
  }
  [role="dialog"] .close {
    display: block;
    fill: #06BEE1;
    padding: 0.25em;
    position: absolute;
    right: 0.1875em;
    top: 0.1875em;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    height: 2.75rem;
    width: 2.75rem;
  }
  [role="dialog"] .close:focus,
  [role="dialog"] .close:hover {
    fill: #5a5a5a;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #5a5a5a;
    font-family: sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #5a5a5a;
    font-weight: 700;
  }
  .arbeitnehmer_online_text {
    fill: #5a5a5a;
    font-family: sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #5a5a5a;
  }
  .arbeitnehmer_online_icon {
    fill: #06BEE1;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #06BEE1;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #06BEE1;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #5a5a5a;
  }
  .arbeitnehmer_online_lines {
    fill: #06BEE1;
  }
  .u_online_secure_login {
    margin-bottom: 1.5em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #5a5a5a;
    font-family: sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #5a5a5a;
    font-weight: 700;
  }
  .u_online_text {
    fill: #5a5a5a;
    font-family: sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #5a5a5a;
  }
  .u_online_warning_icon {
    fill: #5a5a5a;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #5a5a5a;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #06BEE1;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #06BEE1;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #06BEE1;
  }
  .u_online_button:hover .u_online_icon {
    fill: #5a5a5a;
  }
  .u_online_lines {
    fill: #06BEE1;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    text-align: center;
  }
  .u_online_benefits_flow ul {
    list-style: none !important;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: none;
  }
  .u_online_benefits_flow .arrow_small {
    display: block;
    height: 4rem;
    margin: 0 auto;
    width: 1.875rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .u_online_arrow_main_color {
    fill: #06BEE1;
  }
  .u_online_benefits_flow .u_online_arrow_main_color_medium {
    fill: #83dff0;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.75em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header .arrow_small {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.75em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.5em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-color: #06BEE1;
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #f3f3f3;
    display: table;
    margin: 0 auto 0.75em;
    padding: 1.5em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #06BEE1;
    border-left-width: 0.0625rem;
    border-radius: 0.125rem 0 0 0.125rem;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #5a5a5a;
    display: block;
    font-size: 130%;
    font-weight: 500;
    margin-right: 0.1875rem;
    padding: 0 1.5em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
    line-height: 2.75rem;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #06BEE1;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0.125rem 0.125rem 0;
    content: "";
    display: table-cell;
    position: relative;
    -webkit-transition: background-color 300ms;
    transition: background-color 300ms;
    vertical-align: middle;
    z-index: 1;
    height: 2.75rem;
    width: 2.75rem;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #5a5a5a;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #5a5a5a;
    border-radius: 0.125rem 0 0 0.125rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #0594af;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #0594af;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #06BEE1;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(6, 190, 225, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #06BEE1;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #f3f3f3;
    border-radius: 0.125rem;
    color: #5a5a5a;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: baseline;
            align-items: baseline;
    background: #f3f3f3;
    border-radius: 0.125rem;
    color: #5a5a5a;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #dadada;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .infolist_anchor_overview {
    background: #f3f3f3;
    border-radius: 0.125rem;
    padding: 0.75em 1.5em;
  }
  .infolist_anchor_overview a {
    display: block;
    padding: 0.75em 0;
  }
  .infolist_anchor_overview li {
    border-top: 0.0625rem solid #dadada;
  }
  .infolist_anchor_overview li:first-child {
    border-top: 0;
  }
  body.verbraucherpreisindizes .switch_view {
    cursor: pointer;
    margin-bottom: 0.75em;
  }
  .vpi_diagram_grid_wrapper {
    border: 0.0625rem solid #dadada;
    margin-bottom: 1.5em;
    padding: 0.75em 1.5em 0.75em 0.75em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram {
    line-height: 1;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid_points_wrapper {
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
    padding: 0.5em 0;
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_year_label {
    line-height: 1;
    margin: 0;
    padding: 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid {
    height: 18.75rem;
    padding: 0.75em 0 0.75em 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid line {
    stroke: #bbbbbb;
    stroke-width: 0.03125rem;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid text {
    text-anchor: end;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_points {
    height: 18.75rem;
    left: 0;
    padding: 0.75em 0 0.75em 3.75rem;
    position: absolute;
    right: 2.25em;
    top: 0;
    z-index: 1;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    cursor: pointer;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #06BEE1;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #06BEE1;
    stroke-width: 0.125rem;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend {
    height: 2.5rem;
    margin-top: -1.25rem;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend text {
    text-anchor: end;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months_wrapper {
    height: 3.125rem;
    margin-left: 3.75rem;
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months text {
    text-anchor: middle;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output_wrapper {
    bottom: 0.75em;
    left: 0;
    margin-left: 3.75rem;
    position: absolute;
    right: 0;
    top: 0.75em;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    opacity: 0;
    padding: 1.05em;
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(-120%);
            transform: translateX(-50%) translateY(-120%);
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    z-index: 2;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output.show {
    opacity: 1;
    visibility: visible;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output.right {
    -webkit-transform: translateX(-90%) translateY(-130%);
            transform: translateX(-90%) translateY(-130%);
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output .value {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram text {
    font-size: 85%;
  }
  .map_chart {
    height: 16.25rem;
    background-color: #fff;
    margin: 0 0 1.5em 0;
    max-width: 100%;
    position: relative;
  }
  .map_chart .drag_area {
    bottom: 0;
    left: 3.75rem;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 1;
  }
  .map_chart .drag_area.visible {
    visibility: visible;
  }
  .map_chart .map_chart_wrapper {
    height: 16.25rem;
    border: 0.0625rem solid #dadada;
    cursor: move;
    overflow: hidden;
    padding: 0.375em;
    position: relative;
    z-index: 0;
  }
  .map_chart svg path {
    position: relative;
    stroke: #fff;
    -webkit-transition: fill-opacity 300ms;
    transition: fill-opacity 300ms;
  }
  .map_chart svg path.hasData {
    fill: #06BEE1;
  }
  .map_chart svg path.noData {
    fill: #dddddd;
  }
  .map_chart .map_chart_navigation {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    left: 0.75em;
    position: absolute;
    top: 0.75em;
  }
  .map_chart .map_chart_navigation .zoom {
    -webkit-box-align: center;
            align-items: center;
    background-color: #f3f3f3;
    border: 0.0625rem solid #dadada;
    border-radius: 0.125rem;
    color: #5a5a5a;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 120%;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0 0 0.375em 0;
    padding: 0.375em 0.75em;
    width: 2.5rem;
  }
  .map_chart .map_chart_navigation .zoom.disabled {
    cursor: default;
    opacity: 0.4;
  }
  .map_chart .output {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    min-width: 9.375rem;
    opacity: 0;
    padding: 1.05em;
    position: absolute;
    -webkit-transition: left 300ms, opacity 300ms, top 300ms, visibility 300ms;
    transition: left 300ms, opacity 300ms, top 300ms, visibility 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    z-index: 2;
  }
  .map_chart .output.show {
    opacity: 1;
    visibility: visible;
  }
  .map_chart .touch_info {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 3;
  }
  body.reisekosten .switch_view {
    margin-bottom: 0.75em;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5em 0 0.75em 0;
    padding: 0;
  }
  .social_media_share li {
    line-height: 0;
    list-style: none;
    margin: 0.375em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #5a5a5a;
    cursor: pointer;
    display: block;
    padding-right: 1.0625rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0.125rem;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 400;
    padding-left: 1.0625rem;
    padding-right: 0.75em;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: #06BEE1;
    border-radius: 50%;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_share a.facebook .icon,
  .social_media_share a.twitter .icon {
    border-radius: 0;
  }
  .social_media_share a.whatsapp {
    display: none;
  }
  .tablet .social_media_share a.whatsapp,
  .mobile .social_media_share a.whatsapp {
    display: block;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    line-height: 0;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 1.5em;
  }
  .steuernews_preview .photo .bg_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 10rem;
    margin-right: 1.5em;
    width: 10rem;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    word-wrap: break-word;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: right;
    margin-left: 1.5em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    padding-left: 0;
  }
  .steuernews_issues a {
    background: #f3f3f3;
    border-radius: 0.125rem;
    display: inline-block;
    margin: 0 0.75em 0.75em 0;
    padding: 0 1.5em;
    white-space: nowrap;
    line-height: 2.75rem;
  }
  .steuernews_disclaimer {
    clear: both;
  }
  .steuernews_sidebar {
    margin: 1.5em 0 0.75em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.75em;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  [role="dialog"] .close {
    height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [role="dialog"] .close {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  [role="dialog"] .close {
    width: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [role="dialog"] .close {
    width: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 20rem) {
  .u_online_benefits_flow .benefits_button .text {
    line-height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button .text {
    line-height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .u_online_benefits_flow .benefits_button:after {
    height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .u_online_benefits_flow .benefits_button:after {
    width: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    width: 3.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: block;
  }
  .u_online_benefits_flow .arrow_small {
    display: none;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.75em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.75em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer .arrow_large {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.8em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.5em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
    padding-left: 3.75rem;
  }
}
@media screen and (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram text {
    font-size: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .map_chart {
    height: calc(16.25rem + 12.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .map_chart {
    height: 28.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .map_chart .map_chart_wrapper {
    height: calc(16.25rem + 12.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .map_chart .map_chart_wrapper {
    height: 28.75rem;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues a {
    line-height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues a {
    line-height: 3.375rem;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 11.25rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
